import Vue from 'vue'
import $ from "jquery";
import Options from 'vue-class-component'
import LoginLayout from '@/layouts/LoginLayout/index.vue'


@Options({
    components:{
        LoginLayout,
    }
})
export default class Login extends Vue{
    mounted()
    {
        $(function() {
            $('#slideshow > div:gt(0)').hide();
            setInterval(function() {
                $('#slideshow > div:first')
                .fadeOut(1000)
                .next()
                .fadeIn(1000)
                .end()
                .appendTo('#slideshow');
            }, 3850);
        });
    }
}